export const MODULE = {
  HOME: "0",
  PROJECT: "1",
  TASK_RECORD: "2",
  SCHEDULE_CENTER: "3",
  SPIDER_TEMPLATE: "4",
  RECYCLE_BIN: "5",
};
export const PROJECT_MODULE = {
  OVERVIEW: "0",
  SPIDER: "1",
  TASK: "2",
  DATA: "3",
  LOG: "4",
  SCHEDULE: "5",
};
