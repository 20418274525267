<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-size: 14px;
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #101010;
}
</style>
