import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { MODULE, PROJECT_MODULE } from "@/constant/router/module-name";
import VueCookies from "vue-cookies";
import { ElMessage } from "element-plus";
import { httpPostRefreshToken, httpPost } from "@/api/httpService";
import { goLogin } from "../utils/login";

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    component: () =>
      import(/*webpackChunkName:'Index'*/ "@/components/index/index"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/*webpackChunkName:'Index'*/ "@/views/login"),
  },
  {
    path: "/case",
    name: "Case",
    component: () =>
      import(/*webpackChunkName:'Privacy'*/ "@/components/index/case"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/*webpackChunkName:'Privacy'*/ "@/components/index/pricing"),
  },

  {
    path: "/plugin/download",
    name: "PluginDownload",
    component: () =>
      import(
        /*webpackChunkName:'PluginDownload'*/ "@/components/plugin/download"
      ),
  },

  // {
  //   path: "/pay",
  //   name: "PayOrder",
  //   component: () =>
  //     import(/*webpackChunkName:'Privacy'*/ "@/components/index/payOrder"),
  // },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/*webpackChunkName:'Privacy'*/ "@/components/privacy"),
  },
  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(/*webpackChunkName: "Layout" */ "@/components/layout/BaseLayout"),
    children: [
      /* 首页模块 */
      {
        path: "/home",
        name: "HomeIndex",
        component: () =>
          import(/*webpackChunkName: "Home" */ "@/views/home/Index"),
        meta: {
          module: MODULE.HOME,
          ifHidePadding: true,
          ifHidePageHeader: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          ifHideBackground: true,
        },
      },
      /* 项目模块 */
      {
        path: "/project/index",
        name: "ProjectIndex",
        component: () =>
          import(/*webpackChunkName: "Home" */ "@/views/project/Index"),
        meta: {
          module: MODULE.PROJECT,
          // ifHidePadding: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          // ifHideBackground: true,
        },
      },
      {
        path: "/project/",
        // name: "ProjectLayout",
        redirect: "/project/:projectId/index",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/components/layout/ProjectLayout"
          ),
        children: [
          {
            path: "/project/:projectId/index",
            name: "ProjectOverview",
            component: () =>
              import(
                /*webpackChunkName: "Project" */ "@/views/project/Overview"
              ),
            meta: {
              ifHideFooter: true,
              projectModule: PROJECT_MODULE.OVERVIEW,
            },
          },
          {
            path: "/project/:projectId/spider",
            name: "ProjectSpider",
            component: () =>
              import(/*webpackChunkName: "Project" */ "@/views/project/Spider"),
            meta: {
              ifHideFooter: true,
              ifHidePadding: true,
              ifShowSpiderStatus: true,
              projectModule: PROJECT_MODULE.SPIDER,
            },
          },
          {
            path: "/project/:projectId/task",
            name: "ProjectTaskview",
            component: () =>
              import(/*webpackChunkName: "Project" */ "@/views/project/Task"),
            meta: {
              ifHideFooter: true,
              projectModule: PROJECT_MODULE.TASK,
            },
          },
          {
            path: "/project/:projectId/data",
            name: "ProjectData",
            component: () =>
              import(/*webpackChunkName: "Project" */ "@/views/project/Data"),
            meta: {
              ifHideFooter: true,
              projectModule: PROJECT_MODULE.DATA,
            },
          },
          {
            path: "/project/:projectId/log",
            name: "ProjectLogview",
            component: () =>
              import(/*webpackChunkName: "Project" */ "@/views/project/Log"),
            meta: {
              ifHideFooter: true,
              projectModule: PROJECT_MODULE.LOG,
            },
          },
          {
            path: "/project/:projectId/schedule",
            name: "ProjectSchedule",
            component: () =>
              import(
                /*webpackChunkName: "Project" */ "@/views/project/Schedule"
              ),
            meta: {
              ifHideFooter: true,
              projectModule: PROJECT_MODULE.SCHEDULE,
            },
          },
        ],
        meta: {
          module: MODULE.PROJECT,
          ifHidePadding: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          ifHideBackground: true,
        },
      },

      /* 任务记录模块 */
      {
        path: "/task-record",
        name: "TaskRecordIndex",
        component: () =>
          import(/*webpackChunkName: "Home" */ "@/views/taskRecord/Index"),
        meta: {
          module: MODULE.TASK_RECORD,
          // ifHidePadding: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          // ifHideBackground: true,
        },
      },
      /* 策略中心模块 */
      {
        path: "/schedule-center",
        name: "ScheduleCenterIndex",
        component: () =>
          import(/*webpackChunkName: "Home" */ "@/views/scheduleCenter/Index"),
        meta: {
          module: MODULE.SCHEDULE_CENTER,
          ifHidePadding: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          ifHideBackground: true,
        },
      },
      /* 爬虫模板模块 */
      {
        path: "/spider-template",
        name: "SpiderTemplateIndex",
        component: () =>
          import(
            /*webpackChunkName: "Home" */ "@/views/spiderTemplate/SpiderTemplateIndex"
          ),
        children: [],
        meta: {
          module: MODULE.SPIDER_TEMPLATE,
          ifHidePadding: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          ifHideBackground: true,
        },
      },
      {
        path: "/spider-template/:spiderUid/detail",
        name: "SpiderTemplateMarketDetail",
        component: () =>
          import(
            /*webpackChunkName: "Home"*/ "@/views/spiderTemplate/components/SpiderMarketDetail.vue"
          ),
      },
      /* 数据结构模板管理 */
      {
        path: "/data-structure-module",
        name: "DataStructureModuleIndex",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/spiderTemplate/DataStructureModuleIndex"
          ),
        meta: {
          module: MODULE.SPIDER_TEMPLATE,
          ifHideFooter: true,
        },
      },
      {
        path: "/data-structure-module/detail/:dataId",
        name: "DataStructureModuleDetailIndex",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/spiderTemplate/DataStructureModuleDetailIndex"
          ),
        meta: {
          module: MODULE.SPIDER_TEMPLATE,
          ifHideFooter: true,
        },
      },
      /* 回收站模块 */
      {
        path: "/recycle-bin",
        name: "RecycleBin",
        component: () =>
          import(/*webpackChunkName: "Home" */ "@/views/recycleBin/Index"),
        meta: {
          module: MODULE.RECYCLE_BIN,
          ifHidePadding: true,
          // ifHideFooter: true,
          // ifHideMargin: true,
          ifHideBackground: true,
        },
      },
      /**用户权益 */
      {
        path: "/user/equity",
        name: "UserEquity",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/user/Equity.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//路由守卫
const whiteList = [
  "/",
  "/index",
  "/case",
  "/pricing",
  "/pay",
  "/login",
  "/register",
  "/findPassword",
  "/privacy",
];
router.beforeEach(async (to, from, next) => {
  // console.log("to", to);
  // 刷新Token
  if (!VueCookies.isKey("tokenWeb") && VueCookies.isKey("tokenWebRefresh")) {
    await httpPostRefreshToken(
      "/oauth/token",
      VueCookies.get("tokenWebRefresh")
    );
  }

  if (
    VueCookies.isKey("tokenWeb") &&
    VueCookies.isKey("userInfo") &&
    VueCookies.isKey("tokenWebRefresh")
  ) {
    if (whiteList.indexOf(to.path) == -1 && whiteList.indexOf(to.name) == -1) {
      // httpPost("/user/v1/permission", null).then((res) => {
      //   if (res && res.code == 0) {
      //     let userInfo = VueCookies.get("userInfo");
      //     userInfo.role = res.data.role;
      //     VueCookies.set("userInfo", JSON.stringify(userInfo));
      //   }
      // });
    }
    // 获取用户权限

    if (to.path === "/login") {
      next("/home");
    }

    // console.log("next", to);
    next();
  } else {
    if (
      whiteList.indexOf(to.path) !== -1 ||
      whiteList.indexOf(to.name) !== -1
    ) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      ElMessage({
        type: "info",
        message: "登录信息已失效,请重新登录",
      });
      // next("/login");
      goLogin();
    }
  }
});

export default router;
