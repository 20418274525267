/**存放自定义指令 */
import { nextTick } from "vue";

export const compareWidth = (el) => {
  // 如果没有超出宽度，即子<父 则移除tooltip
  // nextTick 解决el-menu展开获取不到宽度的问题
  nextTick(() => {
    console.log(
      "🚀 ~ nextTick ~ el.querySelector:",
      el.querySelector(".ellipse-text").offsetWidth,
      el.offsetWidth
    );

    if (
      el.querySelector(".ellipse-text") &&
      el.querySelector(".ellipse-text").offsetWidth <= el.offsetWidth &&
      el.offsetWidth != 0
    ) {
      console.log("🚀 ~ nextTick ~ el:", el);

      if (el.querySelector(".el-tooltip__trigger")) {
        const copySpan = el.querySelector(".ellipse-text"); //获取 ellipse-text
        const copyTip = el.querySelector(".el-tooltip__trigger"); //获取 tooltip 元素
        el.removeChild(copyTip); //删除 tooltip 元素
        el.appendChild(copySpan); //添加 ellipse-text 元素
      }
    }
  });
};
// 判断元素是否超过高度来决定是否显示按钮
export const checkOverflow = (el) => {
  nextTick(() => {
    const scrollHeight = el.scrollHeight;
    const clientHeight = el.clientHeight;
    if (scrollHeight <= clientHeight) {
      let removedChild = el.getElementsByTagName("label")[0];
      if (removedChild) {
        el.removeChild(removedChild);
      }
    }
  });
};
export default {
  install(app) {
    // 注册一个全局自定义指令 `showTip`
    app.directive("showTip", {
      // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
      created(el, binding) {
        // bind的时候无法获取到已经带有ajax数据的DOM元素，宽度为0
      },
      // 被绑定元素插入父节点时调用 (仅保证父节点存在，但不一定已被插入文档中)。
      mounted(el) {
        compareWidth(el); // 可以获取到ajax数据的DOM元素，即真实的宽度
      },
      // 所在组件的 VNode 更新时调用，但是可能发生在其子 VNode 更新之前。指令的值可能发生了改变，也可能没有。但是你可以通过比较更新前后的值来忽略不必要的模板更新
      updated(el) {
        compareWidth(el); // 可以获取到ajax数据的DOM元素，即真实的宽度
      },
    });
    app.directive("check-overflow", {
      mounted(el) {
        // console.log("mounted");
        checkOverflow(el);
      },
      updated(el) {
        // console.log("created");
        checkOverflow(el);
      },
    });
  },
};
