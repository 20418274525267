import { ElMessage } from "element-plus";

/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};
/**错误提示 */
export const remindErrorMessage = (message) => {
  if (!sessionStorage.getItem("cj_canRemindErrorMessage")) {
    sessionStorage.setItem("cj_canRemindErrorMessage", 1);
    ElMessage({
      message: message,
      type: "warning",
    });
    setTimeout(() => {
      sessionStorage.removeItem("cj_canRemindErrorMessage");
    }, 2000);
  }
};

export function formatNumber(number) {
  if (!number) {
    return 0;
  }
  if (number < 1000) {
    return number.toString(); // 如果数字小于1000，直接返回原始数字
  } else if (number < 10000) {
    // 如果数字小于1万，将数字除以1000并保留一位小数，然后加上'k'单位
    return (number / 1000).toFixed(1) + "k";
  } else {
    // 如果数字大于等于1万，将数字除以10000并保留一位小数，然后加上'w'单位
    return (number / 10000).toFixed(1) + "w";
  }
}
export function objectToUrlParams(obj = {}) {
  var params = [];
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key]) {
        params.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
        );
      }
    }
  }
  return params.join("&");
}

export { Debounce };
